export const getEmailBody = data => {
  const { duration, destination, email, selectedActivities, importance } = data;

  const formattedActivities = selectedActivities
    .map(activity => `<li style="margin: 0 0 8px;">${activity}</li>`)
    .join('');

  return `
    <div style="font-family: Arial, sans-serif; font-size: 16px; color: #333; line-height: 1.5; padding: 20px; border: 1px solid #ddd; border-radius: 10px; background-color: #f9f9f9;">
      <p style="margin: 0 0 16px; font-weight: bold; font-size: 18px; color: #2c3e50;">Hello Team,</p>
      <p style="margin: 0 0 16px;">A new form has been submitted with the following details:</p>
      <ul style="padding: 0; list-style-type: none; margin: 0 0 16px;">
        <li style="margin: 0 0 8px;">
          <strong style="color: #2980b9;">Duration:</strong> ${duration}
        </li>
        <li style="margin: 0 0 8px;">
          <strong style="color: #2980b9;">Destination:</strong> ${destination}
        </li>
        <li style="margin: 0 0 8px;">
          <strong style="color: #2980b9;">Importance:</strong> ${importance}
        </li>
        <li style="margin: 0 0 8px;">
          <strong style="color: #2980b9;">Email:</strong> ${email}
        </li>
        <li style="margin: 0 0 8px;">
          <strong style="color: #2980b9;">Selected Activities:</strong>
          <ul style="padding-left: 20px; margin: 8px 0 0;">
            ${formattedActivities}
          </ul>
        </li>
      </ul>
      <p style="margin: 16px 0 0; font-size: 16px; color: #2c3e50;">Best regards,</p>
      <p style="margin: 0; font-size: 16px; color: #2c3e50;">Your Automated System</p>
    </div>
  `;
};
