import React, { useRef, useEffect, useState } from 'react';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import HeroSection from './sections/HeroSection/HeroSection';
import SliderCommunity from './sections/SliderLP/SliderCommunity';
//import SectionHowItWorksNew from '../../components/SectionHowItWorks/SectionHowItWorksNew';
import Footer from '../../components/Footer/FooterNew';
import FeaturedIn from './sections/PressSection/FeaturedIn';
import OurMission from './sections/OurMission/OurMission';
import SectionWhatsNew from './sections/SectionWhatsNew/sectionWhatsNew';
import Cookies from 'universal-cookie';
import css from './LandingPage.css';

const LandingPage = () => {
  const topbarRef = useRef(null);
  const cookies = new Cookies();
  var cookiesMaybe = cookies?.get('language') ? cookies?.get('language') : 'en';
  const [userEmail, setUserEmail] = useState('false');
  var touchMoveEvent = null;
  var wheelEvent = null;
  var position = 0;

  //Random version generator for A/B tests
  const getRandomVersion = () => {
    return Math.random() < 0.5 ? 'versionA' : 'versionB';
  };

  const setLocalStorageWithExpiry = (key, value, ttl) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getLocalStorageWithExpiry = key => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  //_______Random version end

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.document) {
      document?.body?.scrollTo({
        top: 0,
      });
      touchMoveEvent = 'ontouchmove' in document?.documentElement ? 'touchmove' : null;
      wheelEvent = 'onwheel' in document?.createElement('div') ? 'wheel' : 'mousewheel';
      window.addEventListener(wheelEvent, changeCss, false);
      window.addEventListener(touchMoveEvent, changeCss, { passive: true });

      //Random version set
      const existingVersion = getLocalStorageWithExpiry('userVersion');
      if (!existingVersion) {
        const newVersion = getRandomVersion();
        setLocalStorageWithExpiry('userVersion', newVersion, 30 * 24 * 60 * 60 * 1000); // 30days expiration
      }
    }
  }, []);

  const changeCss = () => {
    if (typeof window !== 'undefined' && window?.document) {
      position = document?.body?.scrollTop
        ? document?.body?.scrollTop
        : position !== 0
        ? position
        : 0;
    }
  };

  const pageContent = (
    <div
      data-elbglobals="pagetype:home"
      data-elbcontext="booking:home"
      style={{ overflow: 'hidden', width: '100vw' }}
    >
      <div
        data-elb="section"
        data-elb-section="name:hero;position:1"
        data-elbaction="visible:vis lp-hero-section"
      >
        <HeroSection />
      </div>

      <div
        data-elb="section"
        data-elb-section="name:news;position:3"
        data-elbaction="visible:vis lp-news-section"
      >
        <SectionWhatsNew />
      </div>

      <div
        data-elb="section"
        data-elb-section="name:ourmission;position:4"
        data-elbaction="visible:vis lp-ourmission-section"
      >
        <OurMission />
      </div>
      {/* removed temporary   <SectionHowItWorksNew/> */}

      <div
        data-elb="section"
        data-elb-section="name:community;position:5"
        data-elbaction="visible:vis lp-community-section"
      >
        <SliderCommunity />
      </div>

      <div
        data-elb="section"
        data-elb-section="name:featuredin;position:6"
        data-elbaction="visible:vis lp-feat-section"
      >
        <FeaturedIn />
      </div>
    </div>
  );

  return (
    <Page
      title={
        cookiesMaybe == 'en'
          ? 'socialbnb – explore sustainable travel worldwide'
          : 'socialbnb - Erlebe nachhaltige Reisen weltweit'
      }
      keywords={
        cookiesMaybe == 'en'
          ? 'accommodation, bed and breakfast, holiday cottage, lodging, farm stay, unique cottages, vacation, travel deals, travel, trip planner, tourist places, sustainable travel, eco lodge, holiday homes, b&b, b an b'
          : 'urlaub buchen, ferienunterkünfte, ferienwohnungen, ferienhaus, hostels, verrückte übernachtungen, unterkunft in der nähe, hotel, besondere unterkünfte, außergewöhnliche Übernachtungen, urlaubsunterkünfte, außergewöhnliche hotels, außergewöhnliches ferienhaus, besondere übernachtungen, außergewöhnliche hotels, besondere hotels, außergewöhnliche unterkünfte, glamping, camping, unterkunft, preiswert übernachten, günstig übernachten, unterkunft buchen, b &b (LÜCKE!), bed und breakfast, bed and breakfast in der nähe, bed and breakfast, nachhaltige unterkünfte, verantwortungsvolles reisen, nachhaltige reiseziele, campingplätze, Eco-Hotels'
      }
      description={
        cookiesMaybe == 'en'
          ? 'Explore sustainable travel with socialbnb. Book sustainable accommodations, hotels, b&bs, experiences and trips that support local communities. Travel with social impact. Discover now!'
          : 'Erlebe nachhaltiges Reisen mit socialbnb. Buche nachhaltige Unterkünfte, Hotels, B&bs, Erlebnisse und Rundreisen die einen positiven Einfluss auf lokale Gemeinschaften haben.'
      }
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'socialbnb',
        url: 'https://socialbnb.org/',
      }}
      noChildrenMargin={true}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={
          <div
            id="topbar"
            ref={topbarRef}
            className={css.topbarScrolled}
            style={{ position: 'fixed', width: '100vw', top: 0 }}
            data-elbaction={`wait(10):isAuthenticated:${userEmail}`}
          >
            <TopbarContainer setUserEmail={setUserEmail} />
          </div>
        }
        footer={
          <div
            data-elb="section"
            data-elb-section="name:footer;position:7"
            data-elbaction="visible"
          >
            <Footer />
          </div>
        }
      >
        <div id="my-scrollbar" className={css.Wrapper}>
          {pageContent}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default LandingPage;
