import React from 'react';
import { object, string, shape, any } from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { bool } from 'prop-types';

export const NamedLinkNewTabComponent = props => {
  const routeConfiguration = useRouteConfiguration();
  const { name, params, title } = props;

  const onOver = () => {
    const { component: Page } = findRouteByRouteName(name, routeConfiguration);
    if (Page.preload) {
      Page.preload();
    }
  };

  const { to, children } = props;
  const pathname = pathByRouteName(name, routeConfiguration, params);
  const { match } = props;
  const active = match.url && match.url === pathname;

  const { className, style, activeClassName } = props;
  const aElemProps = {
    className: classNames(className.toString(), { [activeClassName]: active }),
    style,
    title,
    href: pathname,  //  href to specify the link destination
    target: '_blank',  // Open link in new tab
    rel: 'noopener noreferrer',  // Security measure for links opening in a new tab
  };

  return (
    <a onMouseOver={onOver} onTouchStart={onOver} {...aElemProps}>
      {children}
    </a>
  );
};

NamedLinkNewTabComponent.defaultProps = {
  params: {},
  to: {},
  children: null,
  className: null,
  style: {},
  activeClassName: 'NamedLink_active',
  title: null,
  match: {},
};

NamedLinkNewTabComponent.displayName = 'NamedLink';

NamedLinkNewTabComponent.propTypes = {
  name: string.isRequired,
  params: object,
  to: shape({ search: string, hash: string, state: object }),
  children: any,
  className: string,
  style: object,
  activeClassName: string,
  title: string,
  match: object
};

const NamedLinkNewTab = withRouter(NamedLinkNewTabComponent);
NamedLinkNewTab.displayName = 'NamedLink';

export default NamedLinkNewTab;
