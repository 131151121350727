import React, { useEffect, useState } from 'react';
import { string, func, bool, any, object } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl, useIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpersOld';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import getCountryCodes from '../../translations/countryCodes';

//img
import singlebedroom from '../../assets/icons/roomtypes/onebedroom.png';
import doublebedroom from '../../assets/icons/roomtypes/doublebedroom.png';
import twobedroom from '../../assets/icons/roomtypes/twobedroom.png';
import shared_bedroom from '../../assets/icons/roomtypes/shared_bedroom.png';
import multi_bedroom from '../../assets/icons/roomtypes/multi_bedroom.png';
import entire_accomodation from '../../assets/icons/roomtypes/entire_accomodation.png';
import tent from '../../assets/icons/roomtypes/camping.png';

import Bildung from '../../assets/categories/education.webp';
import Naturschutz from '../../assets/categories/environment.webp';
import Tierschutz from '../../assets/categories/animal.webp';
import Equality from '../../assets/categories/equality.webp';
import Health from '../../assets/categories/health.webp';
import Sports from '../../assets/categories/sports.webp';
import NamedLinkNewTab from '../NamedLink/NamedLinkNewTab';
import { extractDaysAndNights } from '../../util/listingPages.util';
import oneDay from '../../assets/experience-icons/1d.webp';
import twoDays from '../../assets/experience-icons/2d.webp';
import threeDays from '../../assets/experience-icons/3d.webp';
import fourDays from '../../assets/experience-icons/4d.webp';
import fiveDays from '../../assets/experience-icons/5d.webp';
import sixDays from '../../assets/experience-icons/6d.webp';
import OneWeek from '../../assets/experience-icons/1week.webp';
import TwoWeeks from '../../assets/experience-icons/2weeks.webp';
import ThreeWeeks from '../../assets/experience-icons/3weeks.webp';
import ReviewRatings from '../../containers/ListingPage/ListingPageComponents/ReviewRatings/ReviewRatings';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchReviews } from '../../containers/ListingPage/ListingPage.duck';

const optionImageMap = {
  singlebedroom: singlebedroom,
  doublebedroom: doublebedroom,
  twobedroom: twobedroom,
  shared_bedroom: shared_bedroom,
  multi_bedroom: multi_bedroom,
  entire_accomodation: entire_accomodation,
  tent: tent,

  Bildung: Bildung,
  Naturschutz: Naturschutz,
  Tierschutz: Tierschutz,
  Equality: Equality,
  Health: Health,
  Sports: Sports,
};

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl, isUsd) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price, isUsd);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getReviewsByListingId = (state, listingId) => {
  return state.reviews[listingId] || [];
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    fetchAndLogReviews,
  } = props;
  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing?.id?.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const [renderComplete, isRenderComplete] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);
  useEffect(() => {
    isRenderComplete(true);
    const fetchReviews = async () => {
      if (currentListing?.id?.uuid && currentListing?.author?.id?.uuid) {
        try {
          let reviews = await fetchAndLogReviews(id, currentListing.author.id.uuid);
          setReviewsData(reviews);
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      }
    };

    fetchReviews(); // Call the async function
  }, [fetchAndLogReviews, currentListing?.author?.id?.uuid]);

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  /*   const authorName = author.attributes.profile.displayName; */
  const imagesOrder = listing?.attributes?.publicData?.imagesOrder;

  // roomType
  const roomType = props.listing.attributes.publicData.roomtype;
  // function to get roomType
  const getRoomType = type => {
    if (type === 'singlebedroom') {
      return <FormattedMessage id="roomtypes.singlebedroom" />;
    } else if (type === 'doublebedroom') {
      return <FormattedMessage id="roomtypes.doublebedroom" />;
    } else if (type === 'twobedroom') {
      return <FormattedMessage id="roomtypes.twobedroom" />;
    } else if (type === 'shared_bedroom') {
      return <FormattedMessage id="roomtypes.shared_bedroom" />;
    } else if (type === 'multi_bedroom') {
      return <FormattedMessage id="roomtypes.multi_bedroom" />;
    } else if (type === 'entire_accomodation') {
      return <FormattedMessage id="roomtypes.entire_accomodation" />;
    } else if (type === 'tent') {
      return <FormattedMessage id="roomtypes.tent" />;
    } else {
      return <p>not found</p>;
    }
  };
  // title

  const titleFirstPart = title.split('•');
  // location & country in german or english
  const language = intl.locale.split('-')[0];

  const currentListingCountryCode = props.listing.attributes.publicData.country;
  const countriesInLanguage = getCountryCodes(language);
  const matchingCountry = countriesInLanguage.find(
    country => country.code === currentListingCountryCode
  );

  const city = props.listing.attributes.publicData.city;
  // categories

  const categories = props.listing.attributes.publicData.category;

  const firstChosenImageId =
    Array.isArray(imagesOrder) && imagesOrder?.length > 0 && imagesOrder[0]?.id;

  const firstImageBasic =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const firstImage = firstChosenImageId
    ? currentListing.images && currentListing.images.length > 0
      ? currentListing.images.find(img => img?.id?.uuid === firstChosenImageId) || firstImageBasic
      : firstImageBasic
    : firstImageBasic;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const selectedCurrency = publicData?.customCurrency;
  const isUsd = selectedCurrency === 'Dollar';
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl, isUsd);

  let rmvChars = formattedPrice.replace(/[^0-9\.,]/g, '');
  let replaceX = rmvChars.replace(',', '.');
  let rmvPoints = replaceX.replace(/^([^.]*)\.(.*)\./, '$1$2.');
  let roundedPrice = Math.ceil(rmvPoints);
  let finalPrice = `${roundedPrice}€`;
  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const googleReviews = currentListing.attributes.publicData?.googleReviews ?? [];
  const googleRating = currentListing.attributes.publicData?.googleRating ?? 0;
  const isExperienceType =
    currentListing.attributes?.publicData?.listingType == 'impact-experience';
  const getDaysAndNights = isExperienceType
    ? extractDaysAndNights(currentListing?.attributes?.publicData?.MDE)
    : '';
  const translatedMDELabel = isExperienceType ? (
    getDaysAndNights.days == 7 ? (
      <FormattedMessage id="searchPage.experienceLabel1Week" />
    ) : getDaysAndNights.days == 14 ? (
      <FormattedMessage id="searchPage.experienceLabel2Weeks" />
    ) : getDaysAndNights.days == 21 ? (
      <FormattedMessage id="searchPage.experienceLabel3Weeks" />
    ) : (
      <FormattedMessage
        id="listingPage.experiencePackageCardLabel"
        values={{ days: getDaysAndNights.days, nights: getDaysAndNights.nights }}
      />
    )
  ) : (
    ''
  );
  const experienceImages = [
    oneDay,
    twoDays,
    threeDays,
    fourDays,
    fiveDays,
    sixDays,
    OneWeek,
    TwoWeeks,
    ThreeWeeks,
  ];

  const hostId = currentListing?.author?.id.uuid;

  return (
    <NamedLinkNewTab className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.roomType}>
            {renderComplete ? (
              (Number(googleRating) < 1 || !googleReviews?.length) &&
              !reviewsData?.reviews?.length ? (
                <div className={css.emptyPlaceholder}> </div>
              ) : (
                <div className={css.reviewWrapper}>
                  <ReviewRatings
                    currentListing={currentListing}
                    reviews={reviewsData.reviews}
                    googleReviews={googleReviews}
                    rating={googleRating}
                    onShowModalFromOutside={() => {}}
                    forSearchPage={true}
                  />
                </div>
              )
            ) : (
              <></>
            )}
            <span className={css.roomTypeText}>
              {isExperienceType
                ? translatedMDELabel
                : richText(getRoomType(roomType), {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
            </span>
          </div>
          <div className>
            <div className={css.title}>
              {richText(
                titleFirstPart[0].length > 22
                  ? titleFirstPart[0].slice(0, 22) + '...'
                  : titleFirstPart[0],
                {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                }
              )}
            </div>
            <div className={css.subtitle}>
              {` ${city}, ${matchingCountry && matchingCountry.name}`}
            </div>
          </div>

          <div className={css.categoriesPriceContainer}>
            <div className={css.categoriesContainer}>
              {Array.isArray(categories) ? (
                categories.map((category, index) => (
                  <div className={css.categoriesGroup} key={index}>
                    <div className={css.categories} key={index}>
                      <div>
                        <img
                          className={css.categoryImg}
                          src={optionImageMap[category]}
                          alt={category}
                        />
                      </div>
                      <div>
                        <p className={css.categoryName}>
                          <FormattedMessage id={`filter.category.${category}`} />
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={css.categories}>
                  <div className={css.categoriesGroup}>
                    <img
                      className={css.categoryImg}
                      src={optionImageMap[categories]}
                      alt={categories}
                    />

                    <p className={css.roomType}>{categories}</p>
                  </div>
                </div>
              )}
            </div>

            <div className={css.roomTypePriceContainer}>
              {/* Your content for the single roomtype */}
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {isExperienceType ? <FormattedMessage id="ListingCard.perUnitFrom" /> : null}
                  {finalPrice}
                </div>
                {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
                  <div className={css.perUnit}>
                    {isExperienceType ? null : (
                      <FormattedMessage
                        id="ListingCard.perUnit"
                        values={{ unitType: publicData?.unitType }}
                      />
                    )}
                  </div>
                ) : null}
              </div>
              {isExperienceType ? (
                <div className={css.expImageContainer}>
                  <img
                    className={css.expImage}
                    src={
                      experienceImages[
                        getDaysAndNights.days > 6
                          ? Number(getDaysAndNights.days) / 7 + 5
                          : Number(getDaysAndNights.days) - 1
                      ]
                    }
                  />
                </div>
              ) : (
                <img className={css.roomtypeImg} src={optionImageMap[roomType]} alt={categories} />
              )}
            </div>
          </div>

          {/* {showAuthorInfo ? (
            <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null} */}
        </div>
      </div>
    </NamedLinkNewTab>
  );
};

/* ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
}; */

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: object.isRequired,
  showAuthorInfo: bool,
  renderSizes: string,
  setActiveListing: func,
  fetchAndLogReviews: func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  fetchAndLogReviews: (listingId, hostId) => dispatch(fetchReviews(listingId, hostId, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingCardComponent);
