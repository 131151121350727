import React, { useCallback, useState } from 'react';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { useConfiguration } from '../../../../context/configurationContext';
import css from '../../navbar.module.css';
import TopbarSearchForm from '../../../Topbar/TopbarSearchForm/TopbarSearchForm';
import SearchIcon from '../../../Topbar/SearchIcon';

export const TopBarSearch = props => {
  const { currentSearchParams, history, location } = props;
  const [locationInput, setLocationInput] = useState('');
  const config = useConfiguration();

  const handleSubmit = values => {
    const keywords = values.keywords;
    const search = values?.location?.search ? values?.location?.search : '';
    const selectedPlace = values?.location?.selectedPlace ? values?.location?.selectedPlace : '';
    const { origin, bounds } = selectedPlace;
    const currentSearchParams = { bounds, origin };
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      keywords,
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    if (search != '' || selectedPlace != '') {
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    } else {
      history.push('/s');
    }
  };

  const handleLocationChange = v => {
    setLocationInput(v);
  };
  if (location.pathname.startsWith('/s')) {
    return null;
  } else {
    return (
      <div className={css.searchBar}>
        <SearchIcon className={css.searchIcon} />
        <TopbarSearchForm
          onSubmit={handleSubmit}
          initialSearchFormValues
          isMobile={false}
          handleLocationChange={handleLocationChange}
          isTobarSearch={true}
        />
      </div>
    );
  }
};
