import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatPriceFromUnitPurchase } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { bool, object } from 'prop-types';
import { extractDaysAndNights } from '../../util/listingPages.util';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, showPriceDetailsMaybe } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const isExperienceType =
    props.currentListing?.attributes?.publicData?.listingType == 'impact-experience';

  const createPeoplePriceArray = data => {
    const peoplePrice = [];

    if (data.price) {
      peoplePrice.push({ key: 'price', value: data.price.amount, guests: 1 });
    }

    const prices = ['price2P', 'price3P', 'price4P'];
    const guestKeys = [2, 3, 4];
    prices.forEach((priceKey, index) => {
      if (data.publicData[priceKey]) {
        peoplePrice.push({
          key: priceKey,
          value: data.publicData[priceKey].amount,
          guests: guestKeys[index],
        });
      }
    });

    return peoplePrice;
  };
  const availablePeopleMDE = props.currentListing?.attributes
    ? createPeoplePriceArray(props.currentListing?.attributes)
    : [];

  const getPriceForSeats = seats => {
    return availablePeopleMDE.find(MDE => MDE.guests == seats);
  };
  const experiencePrice = unitPurchase?.seats ? getPriceForSeats(unitPurchase?.seats) : {};
  const totalExperiencePrice = '€' + Number(experiencePrice?.value) / 100;
  const finalExperienceLabel = totalExperiencePrice + ' - ' + experiencePrice?.guests + ' Guests';
  const getDaysAndNights = isExperienceType ? extractDaysAndNights(props?.currentListing?.attributes?.publicData?.MDE) : "";
  const translatedMDELabel = isExperienceType ? <FormattedMessage id="listingPage.experienceLineItemDetails" values={{days:getDaysAndNights.days , nights :getDaysAndNights.nights }} /> : ""
  const guestOrGuests =
    Number(experiencePrice?.guests) == 1 ? (
      <FormattedMessage id="checkoutPage.oneGuest" />
    ) : (
      <FormattedMessage id="checkoutPage.manyGuests" />
    );
  const finalExperienceLabel1 = <span> {experiencePrice?.guests} {guestOrGuests} {" - "}{translatedMDELabel} </span>;

  const quantity = unitPurchase?.units
    ? unitPurchase.units.toString()
    : unitPurchase?.quantity
    ? unitPurchase.quantity.toString()
    : null;

  const unitPrice = unitPurchase
    ? formatPriceFromUnitPurchase(intl, unitPurchase.unitPrice.amount)
    : null;
  const total = unitPurchase
    ? formatPriceFromUnitPurchase(intl, unitPurchase.lineTotal.amount)
    : null;
  const message = <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />;

  const getSeatsPlaceholder = () => {
    let seatsPlaceholder;
    if (unitPurchase.seats == 1) {
      seatsPlaceholder =
        props.roomType == 'shared_bedroom' ? (
          <FormattedMessage
            id="OrderBreakdown.baseUnitBedsSingle"
            values={{ value: unitPurchase.seats }}
          />
        ) : props.roomType == 'tent' ? (
          <FormattedMessage
            id="OrderBreakdown.baseUnitTentsSingle"
            values={{ value: unitPurchase.seats }}
          />
        ) : (
          <FormattedMessage
            id="OrderBreakdown.baseUnitRoomsSingle"
            values={{ value: unitPurchase.seats }}
          />
        );
    } else {
      seatsPlaceholder =
        props.roomType == 'shared_bedroom' ? (
          <FormattedMessage
            id="OrderBreakdown.baseUnitBeds"
            values={{ value: unitPurchase.seats }}
          />
        ) : props.roomType == 'tent' ? (
          <FormattedMessage
            id="OrderBreakdown.baseUnitTents"
            values={{ value: unitPurchase.seats }}
          />
        ) : (
          <FormattedMessage
            id="OrderBreakdown.baseUnitRooms"
            values={{ value: unitPurchase.seats }}
          />
        );
    }
    return seatsPlaceholder;
  };
  const seatsPlaceholder = getSeatsPlaceholder();

  return quantity && total ? (
    <>
      {!showPriceDetailsMaybe ? <hr className={css.totalDividerMaybe} /> : null}
      <div className={css.lineItem}>
        <div className={css.itemLabel}>
          {isExperienceType ? (
            finalExperienceLabel1
          ) : (
            <>
              {' '}
              {message} {seatsPlaceholder}{' '}
            </>
          )}
        </div>
        <div className={css.itemValue}>{isExperienceType ? totalExperiencePrice : total}</div>
      </div>
    </>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
  showPriceDetailsMaybe: bool,
  currentListing: object,
};

export default LineItemBasePriceMaybe;
