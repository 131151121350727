import React, { useState } from 'react';
import CategoryButtonsHero from './CategoryButtonsHero';
import ContentSection from './ContentSection';
import HeroImage from './HeroImage';
import css from './HeroSection.module.css';

const categories = ['stays', 'experiences', 'forYou'];

//'trips'

const HeroSection = () => {
  const [currentCategory, setCurrentCategory] = useState('stays');

  const handleCategoryChange = category => {
    setCurrentCategory(category);
  };

  return (
    <div className={css.landinpageContainer}>
      <HeroImage currentCategory={currentCategory}>
        <CategoryButtonsHero
          currentCategory={currentCategory}
          categories={categories}
          onCategoryChange={handleCategoryChange}
        />
      </HeroImage>
      <ContentSection currentCategory={currentCategory} />
    </div>
  );
};

export default HeroSection;
