import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './HeroSection.module.css';

const CategoryButtonsHero = ({ categories, currentCategory, onCategoryChange }) => {
  return (
    <div className={css.categoryButtons}>
      {categories.map((category) => (
        <button
          key={category}
          className={category === currentCategory ? `${css.button} ${css.active}` : css.button}
          onClick={() => onCategoryChange(category)}
        >
          <FormattedMessage id={`heroSection.category.${category}`} defaultMessage={category.charAt(0).toUpperCase() + category.slice(1)} />
        </button>
      ))}
    </div>
  );
};

export default CategoryButtonsHero;
