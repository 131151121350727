import React, { useState, useEffect, useRef } from 'react';
import css from './HeroSection.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import TextTransition, { presets } from 'react-text-transition';
import OldLogo from '../../../../components/OldLogo/OldLogo';
import globalTribe from '../../../../assets/landingPage/heroImage/globalTribeCompr.webp';
import staysImage from '../../../../assets/landingPage/heroSection/dosaguas1.webp';
import foryouImage from '../../../../assets/landingPage/heroSection/foryou.webp';
import experienceImage from '../../../../assets/landingPage/heroSection/experiences3.png';

const cookies = new Cookies();

const categories = {
  stays: {
    heroImage: staysImage,
    heroText: {
      subHeadline: <FormattedMessage id="heroSectionSubtitleStays" />,
    },
  },
  experiences: {
    heroImage: experienceImage,
    heroText: {
      subHeadline: <FormattedMessage id="heroSectionSubtitleExperiences" />,
    },
  },
  trips: {
    heroImage: globalTribe,
    heroText: {
      subHeadline: <FormattedMessage id="heroSectionSubtitleTrips" />,
    },
  },
  forYou: {
    heroImage: foryouImage,
    heroText: {
      subHeadline: <FormattedMessage id="heroSectionSubtitleForYou" />,
    },
  },
};

const HeroImage = ({ currentCategory, children }) => {
  const intl = useIntl();

  // Default to 'experiences' if currentCategory is invalid or not provided
  const categoryData = categories[currentCategory] || categories.stays;

  const { heroImage, heroText } = categoryData;

  useEffect(() => {
    const linkTag = document.createElement('link');
    linkTag.rel = 'preload';
    linkTag.href = heroImage;
    linkTag.as = 'image';
    document.head.appendChild(linkTag);

    return () => {
      document.head.removeChild(linkTag);
    };
  }, [heroImage]);

  return (
    <div>
      <div className={css.heroWrapper} id="heroWrapper">
        <div className={css.heroBackground}>
          <img className={css.heroBackgroundStatic} src={heroImage} alt="Background Hero Image" />
        </div>
        <div className={css.textWrapper}>
          <div className={css.textSectionWrapper}>
            <div className={css.headerWrapperRow}>
              <h1 className={css.header}>
                <span>
                  <FormattedMessage id="heroSectionTitle1" />{' '}
                </span>
                <span>
                  <FormattedMessage id="heroSectionTitle2" />{' '}
                </span>
                <span>
                  <FormattedMessage id="heroSectionTitle3" />{' '}
                </span>
              </h1>
            </div>
            <div className={css.headerWrapperRow}>
              <h3 className={css.subHead}>{heroText.subHeadline}</h3>
            </div>
            {children}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
