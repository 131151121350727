import css from './SectionWhatsNew.module.css';
import React, {
  useState,
  useEffect,
} from 'react';
import ambassadorImage from '../../../../assets/sectionWhatsNew/ambassador1.webp';
import voucherImg from '../../../../assets/sectionWhatsNew/voucher.webp';
import expImg from '../../../../assets/sectionWhatsNew/exp.webp';
import roundtrip from '../../../../assets/sectionWhatsNew/roundtrip.webp';
import arrowLeft from '../SliderLP/images/Vector 2.svg';
import arrowRight from '../SliderLP/images/Vector 1.svg';

import ExternalLink from '../../components/ExternalLink/ExternalLink.js';
import Cookies from 'universal-cookie';
import Swipe from 'react-easy-swipe';
import { FormattedMessage, useIntl } from 'react-intl';

const SectionWhatsNew = () => {
  const slideDataList = [
    {
      index: 0,
      title: <FormattedMessage id="sectionWhatsNew.cardThreeTitle" />,
      text: <FormattedMessage id="sectionWhatsNew.cardThreeText" />,
      buttontext: <FormattedMessage id="sectionWhatsNew.cardThreeButton" />,
      link: '/p/impact-experiences',
      src: typeof window !== 'undefined' ? (window.innerWidth <= 767 ? expImg : expImg) : expImg,
    },
    {
      index: 1,
      title: <FormattedMessage id="sectionWhatsNew.cardTwoTitle" />,
      text: <FormattedMessage id="sectionWhatsNew.cardTwoText" />,
      buttontext: <FormattedMessage id="sectionWhatsNew.cardTwoButton" />,
      link: '/p/impact-trips',
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? roundtrip
            : roundtrip
          : roundtrip,
    },
    {
      index: 2,
      title: <FormattedMessage id="sectionWhatsNew.cardOneTitle" />,
      text: <FormattedMessage id="sectionWhatsNew.cardOneText" />,
      buttontext: <FormattedMessage id="sectionWhatsNew.cardOneButton" />,
      link: '/ambassadorPage',
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? ambassadorImage
            : ambassadorImage
          : ambassadorImage,
    },
    {
      index: 3,
      title: <FormattedMessage id="sectionWhatsNew.cardFourTitle" />,
      text: <FormattedMessage id="sectionWhatsNew.cardFourText" />,
      buttontext: <FormattedMessage id="sectionWhatsNew.cardFourButton" />,
      link: '/p/gutschein',
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? voucherImg
            : voucherImg
          : voucherImg,
    },
  ];
  const [slideData, setSlideData] = useState(slideDataList);
  const [autoplay, setAutoplay] = useState(true);

  const [index, setIndex] = useState(0);

  const mod = (n, m) => {
    let result = n % m;

    return result >= 0 ? result : result + m;
  };
  const increaseHandler = () => {
    setIndex((index + 1) % slideData.length);
    setAutoplay(false);
  };



  const decreaseHandler = () => {
    setIndex(mod(index - 1, slideData.length));
    setAutoplay(false);
  };

  //swipe logic

  const handleSwipeLeft = () => {
    console.log('swiped left');
    setIndex((index + 1) % slideData.length);
    setAutoplay(false);
  };

  const handleSwipeRight = () => {
    console.log('swiped right');
    setIndex(mod(index - 1, slideData.length));
    setAutoplay(false);
  };

  useEffect(() => {
    if (autoplay) {
      setTimeout(() => {
        setIndex((index + 1) % slideData.length);
      }, 5000);
    } else return;
  }, [index, autoplay]);

  const sliderCards = slideData.map((slide, i) => {
    const indexLeft = mod(index - 1, slideData.length);
    const indexRight = mod(index + 1, slideData.length);

    let className = '';
    if (i === indexLeft) {
      className = `${css['slide']} ${css['slide-0']}`;
    } else if (i === index) {
      className = `${css['slide']} ${css['slide-1']}`;
    } else if (i === indexRight) {
      className = `${css['slide']} ${css['slide-2']}`;
    } else if (i === (indexRight + 1) % slideData.length) {
      className = `${css['slide']} ${css['slide-3']} `;
    } else if (i === (indexRight + 2) % slideData.length) {
      className = `${css['slide']} ${css['slide-4']} `;
    } else if (i === (indexRight + 3) % slideData.length) {
      className = `${css['slide']} ${css['slide-5']}`;
    } else {
      className = `${css['slide']} ${css['hidden']}`;
    }
    const intl = useIntl();
    return (
      <div id={slide.index} key={slide.index} className={className}>
        <img className={css['slide-img']} src={slide.src} alt="slides" />
        <div className={css['slide-text']}>
          <h2 className={css.title}>{slide.title}</h2>
          <p className={css.description}>{slide.text}</p>
          <ExternalLink href={slide.link}  data-elbaction={`click:click lp-news-${intl.formatMessage({id: slide.title.props.id})}`}>
            <button className={css.button}>{slide.buttontext}</button>
          </ExternalLink>
        </div>
      </div>
    );
  });

  return (
    <div className={css['slider-container']}>
      <div className={css['slider-buttons']}>
        <img
          onClick={decreaseHandler}
          className={css['slider-button']}
          src={arrowLeft}
          alt="arrowLeft - socialbnb"
          data-elbaction={'click:click lp-news-swipeleft'}
        />
        <img
          onClick={increaseHandler}
          className={css['slider-button']}
          src={arrowRight}
          alt="arrowRight - socialbnb"
          data-elbaction={'click:click lp-news-swiperight'}
        />
      </div>
      <h2 className={css['slider-title']}>
        <FormattedMessage id="sectionWhatsNew.sectionTitle" />{' '}
      </h2>
      <div className={css['slider-cards_title']}>
        <Swipe
          onSwipeLeft={handleSwipeLeft}
          onSwipeRight={handleSwipeRight}
          allowMouseEvents={true}
          tolerance={50}
          delta={10}
          preventdefaulttouchmoveevent="false"
        >
          {' '}
          <div className={css['slider-cards']}> {sliderCards}</div>
        </Swipe>
      </div>
    </div>
  );
};

export default SectionWhatsNew;
