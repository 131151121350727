import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { array, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { ensureListing } from '../../util/data';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
} from '../../util/types';
import { formatMoney, formatPriceFromUnitPurchase } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  getSupportedProcessesInfo,
  isBookingProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  ModalInMobile,
  Button,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  FieldSelect,
  AvatarMedium,
} from '../../components';
import GetRoomType from '../SharedComponent/GetRoomType/GetRoomType';
import * as validators from '../../util/validators';
import { Form as FinalForm } from 'react-final-form';

import css from './OrderPanel.module.css';
import { isMobile } from 'react-device-detect';
import { extractDaysAndNights } from '../../util/listingPages.util';
const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);
import oneDay from '../../assets/experience-icons/1d.webp';
import twoDays from '../../assets/experience-icons/2d.webp';
import threeDays from '../../assets/experience-icons/3d.webp';
import fourDays from '../../assets/experience-icons/4d.webp';
import fiveDays from '../../assets/experience-icons/5d.webp';
import sixDays from '../../assets/experience-icons/6d.webp';
import OneWeek from '../../assets/experience-icons/1week.webp';
import TwoWeeks from '../../assets/experience-icons/2weeks.webp';
import ThreeWeeks from '../../assets/experience-icons/3weeks.webp';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (typeof window !== 'undefined') {
    if (isOwnListing || isClosed) {
      window.scrollTo(0, 0);
    } else {
      const { pathname, search, state } = location;
      const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;

      history.push(`${pathname}${searchString}`, state);
    }
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    setAuthModalOpen,
    currentUser,
    roomTypeRaw,
    ProjectRoomtype,
    projectRoomtypeRaw,
    bedamount,
    showPriceDetails,
    bookingDisabled,
    showBookingNoAvailableError,
  } = props;
  const currentListing = ensureListing(listing);
  const currencyCheck = (customCurrency, shareTribePrice) => {
    if (customCurrency !== undefined) {
      if (customCurrency.includes('Euro')) {
        return shareTribePrice.substr(1) + '€';
      } else {
        return shareTribePrice;
      }
    } else {
      return shareTribePrice;
    }
  };

  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const unitType = listing?.attributes?.publicData?.unitType;
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const isBook = !!parse(location.search).book;

  const isNightly = `line-item/${unitType}` == LINE_ITEM_NIGHT;
  const isDaily = `line-item/${unitType}` == LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perNight'
    : 'BookingPanel.perNight';

  const price = listing?.attributes?.price;

  const showPriceMissing = !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = 'Etc/UTC'; //listing?.attributes?.availabilityPlan?.timezone;
  // not available for day type, only for hour
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates = true; // needs to be checked later
  //isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const shouldHaveProductOrder = !isBooking && [LINE_ITEM_ITEM].includes(lineItemUnitType);
  const showProductOrderForm = shouldHaveProductOrder && typeof currentStock === 'number';

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);

  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);
  const roomAmountOptions = [
    {
      key: 1,
      label: 1,
    },
    {
      key: 2,
      label: 2,
    },
    {
      key: 3,
      label: 3,
    },
  ];

  const ProjectRoomtypeDisplay =
    roomTypeRaw === 'singlebedroom' ? (
      <FormattedMessage id="roomtypes.singlebedroom" />
    ) : roomTypeRaw === 'twobedroom' ? (
      <FormattedMessage id="roomtypes.twobedroom" />
    ) : roomTypeRaw === 'doublebedroom' || roomTypeRaw === 'doubleRoom' ? (
      <FormattedMessage id="roomtypes.doublebedroom" />
    ) : roomTypeRaw === 'shared_bedroom' ? (
      <FormattedMessage id="roomtypes.shared_bedroom" />
    ) : roomTypeRaw === 'multi_bedroom' ? (
      <FormattedMessage id="roomtypes.multi_bedroom" />
    ) : roomTypeRaw === 'entire_accomodation' ? (
      <FormattedMessage id="roomtypes.entire_accomodation" />
    ) : roomTypeRaw === 'tent' ? (
      <FormattedMessage id="roomtypes.tent" />
    ) : null;
  const isExperienceType =
    currentListing.attributes?.publicData?.listingType == 'impact-experience';
  const listingMDE = currentListing.attributes?.publicData?.MDE ?? '';
  const getDaysAndNights = isExperienceType ? extractDaysAndNights(listingMDE) : '';
  const translatedMDELabel = isExperienceType ? (
    getDaysAndNights.days == 7 ? (
      <FormattedMessage id="searchPage.experienceLabel1Week" />
    ) : getDaysAndNights.days == 14 ? (
      <FormattedMessage id="searchPage.experienceLabel2Weeks" />
    ) : getDaysAndNights.days == 21 ? (
      <FormattedMessage id="searchPage.experienceLabel3Weeks" />
    ) : (
      <FormattedMessage
        id="listingPage.experiencePackageCardLabel"
        values={{ days: getDaysAndNights.days, nights: getDaysAndNights.nights }}
      />
    )
  ) : (
    ''
  );
  const experienceImages = [
    oneDay,
    twoDays,
    threeDays,
    fourDays,
    fiveDays,
    sixDays,
    OneWeek,
    TwoWeeks,
    ThreeWeeks,
  ];

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.bookingWrapper}>
          <div className={css.bookingContainer}>
            {/* show days and nightsinstead of roomtype if experience  + change price per room to price of one person */}
            <div className={css.bookingHeaderSection}>
              <div className={css.bookingHeaderSubSection}>
                {!isExperienceType ? (
                  <GetRoomType withIcon={true} withTitle={false} roomTypeRaw={roomTypeRaw} />
                ) : (
                  <div className={css.expImageContainer}>
                    <img
                      className={css.expImage}
                      src={
                        experienceImages[
                          getDaysAndNights.days > 6
                            ? Number(getDaysAndNights.days) / 7 + 5
                            : Number(getDaysAndNights.days) - 1
                        ]
                      }
                    />
                  </div>
                )}
                <div className={css.priceAndRoomWrapper}>
                  <div className={css.roomTypeTitle}>
                    {' '}
                    {!isExperienceType ? ProjectRoomtypeDisplay : translatedMDELabel}
                  </div>
                  {!isExperienceType ? (
                    price ? (
                      <div className={css.priceContainer}>
                        <span className={css.priceTag}>
                          {formatPriceFromUnitPurchase(intl, price.amount)}
                        </span>
                        /
                        <span className={css.perRoomLabel}>
                          <FormattedMessage
                            id={
                              roomTypeRaw == 'shared_bedroom'
                                ? 'bookingPanel.bedUnit'
                                : roomTypeRaw == 'tent'
                                ? 'bookingPanel.tentUnit'
                                : 'bookingPanel.roomUnit'
                            }
                          />{' '}
                        </span>
                      </div>
                    ) : null
                  ) : (
                    <>
                      <span className={css.perRoomLabel}>
                        <FormattedMessage
                          id="listingPage.experiencePriceLabel"
                          values={{ value: formatPriceFromUnitPurchase(intl, price.amount) }}
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className={css.author}>
                <AvatarMedium user={author} className={css.providerAvatar} />
              </div>
            </div>

            {showPriceMissing ? (
              <PriceMissing />
            ) : showInvalidCurrency ? (
              <InvalidCurrency />
            ) : showBookingDatesForm ? (
              <BookingDatesForm
                className={css.bookingForm}
                formId="OrderPanelBookingDatesForm"
                lineItemUnitType={lineItemUnitType}
                onSubmit={onSubmit}
                price={price}
                marketplaceCurrency={marketplaceCurrency}
                dayCountAvailableForBooking={dayCountAvailableForBooking}
                listingId={listing.id}
                currentListing={currentListing}
                isOwnListing={isOwnListing}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                timeZone={timeZone}
                marketplaceName={marketplaceName}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                bedamount={bedamount}
                projectRoomtypeRaw={projectRoomtypeRaw}
                showPriceDetails={showPriceDetails}
                bookingDisabled={bookingDisabled}
                showBookingNoAvailableError={showBookingNoAvailableError}
              />
            ) : !isKnownProcess ? (
              <p className={css.errorSidebar}>
                <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
              </p>
            ) : null}
          </div>
        </div>
      </ModalInMobile>

      <div className={css.openOrderForm}>
        <div className={css.priceContainerMobile}>
          <div className={css.priceValue} title={priceTitle}>
            {isExperienceType ? (
              <div className={css.perUnit}>
                <FormattedMessage id={"landing.accomodations.from"} />
              </div>
            ) : null}
            {formatPriceFromUnitPurchase(intl, price.amount)}
          </div>
          {isExperienceType ? null : (
            <div className={css.perUnit}>
              {' /'}
              <FormattedMessage id={unitTranslationKey} />
            </div>
          )}
        </div>
        {showBookingDatesForm ? (
          <div>
            <PrimaryButton
              rootClassName={css.bookButton}
              onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
            >
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            </PrimaryButton>
          </div>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  showPriceDetails: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,
  showPriceDetails: bool,
  bookingDisabled: bool,
  showBookingNoAvailableError: bool,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
