import { FormattedMessage, useIntl } from 'react-intl';
import Avatar from '../../Avatar/Avatar';
import defaultAvatar from '../assets/user.png';
import defaultAvatarWhite from '../assets/userwhite.png';
import css from '../navbar.module.css';
import { useEffect, useState } from 'react';
import heartImg from '../assets/new-heart-icon.png';
import closeIcon from '../assets/close.png';
import menuIcon from '../assets/menu.png';
import menuIconWhite from '../assets/menuwhite.png';

export const GetAvatar = ({ currentUser, setMenuOpen, menuOpen }) => {
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    setIsPageReady(true);
  }, []);

  const handleMouseEnter = () => setMenuOpen(true);

  if (isPageReady) {
    if (currentUser) {
      return (
        <div className={css.avatarMenuUser}>
          <div className={css.burgerMenu} onMouseEnter={handleMouseEnter}>
            <img
              src={menuIcon}
              className={css.menuBugerIconFull}
              alt="Menu Icon"
            />
            {menuOpen ? (
              <>
                <img
                  src={closeIcon}
                  className={css.closeIcon}
                  alt="Menu Icon"
                />
              </>
            ) : (
              <img
                src={menuIcon}
                className={css.menuBugerIcon}
                alt="Menu Icon"
              />
            )}
            <div className={css.userAvatar}>
              <Avatar
                className={css.avatar}
                rootClassName={css.avatar}
                user={currentUser}
                disableProfileLink
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={css.burgerMenu} >
          <img src={menuIcon} className={css.menuBugerIconFull} alt="Menu Icon" />
          {menuOpen ? (
            <>
              <img src={closeIcon} className={css.closeIcon} alt="Menu Icon" />
            </>
          ) : (
            <>
              <img src={menuIcon} className={css.menuBugerIcon} alt="Menu Icon" />{' '}
            </>
          )}
          <img src={defaultAvatar} className={css.userIcon} alt="user avatar" />
        </div>
      );
    }
  } else return <></>;
};

export const HostButton = props => {
  const { currentUser } = props;
  if (!currentUser) {
    return (
      <div className={css.becomePartnerWrapper} id="hostBTN">
        <a className={css.becomePartnerLink} href="/BecomeHostPage" >
          <span className={css.becomePartner} data-elbaction={'click:click topbar-becomehost'}>
            <FormattedMessage id="TopbarDesktop.becomePartner" />
          </span>
        </a>
      </div>
    );
  }
  return <></>;
};

export const FavoritesButton = props => {
  const { currentUser } = props;

  return (
    <div id="favBTN"
      className={
        typeof window !== 'undefined'
          ? window?.location?.pathname === '/favorite'
            ? css.becomePartnerWrapper_hide
            : css.favoriteWrapper
          : css.favoriteWrapper
      }
    >
      <a className={css.favouritesButton} href="/favorite">
        <span className={css.becomePartner}>
          <img src={heartImg} className={css.heartIcon} />
          <FormattedMessage id="UserNav.favorites" />
        </span>
      </a>
    </div>
  );
};
