import React, { Component } from 'react';
import { bool, func, instanceOf, number, oneOf, shape, string } from 'prop-types';
import { DateRangePicker } from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { START_DATE, END_DATE } from '../../util/dates';
import { IconArrowHead } from '../../components';
import css from './DateRangeInput.module.css';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

const apiEndDateToPickerDate = (isDaily, endDate) => {
  const isValid = endDate instanceof Date;
  return isValid && isDaily
    ? moment(endDate).subtract(1, 'days')
    : isValid
    ? moment(endDate)
    : null;
};

const pickerEndDateToApiDate = (isDaily, endDate) => {
  const isValid = endDate instanceof moment;
  return isValid && isDaily
    ? endDate
        .clone()
        .add(1, 'days')
        .toDate()
    : isValid
    ? endDate.toDate()
    : null;
};

const BLUR_TIMEOUT = 100;

const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" color="1c7881" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const defaultProps = {
  initialDates: null,
  value: null,
  startDateId: 'startDate',
  endDateId: 'endDate',
  startDatePlaceholderText: null,
  endDatePlaceholderText: null,
  disabled: false,
  required: false,
  readOnly: false,
  screenReaderInputMessage: null,
  showClearDates: false,
  showDefaultInputIcon: false,
  customArrowIcon: <span />,
  customInputIcon: null,
  customCloseIcon: null,
  noBorder: true,
  block: false,
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  appendToBody: false,
  disableScroll: false,
  daySize: 38,
  isRTL: false,
  initialVisibleMonth: null,
  firstDayOfWeek: 0,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200,
  renderCalendarDay: undefined,
  renderDayContents: day => <span className="renderedDay">{day.format('D')}</span>,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => () => false,
  isOutsideRange: day => false,
  isDayHighlighted: () => {},
  displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
};

class DateRangeInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      currentStartDate: null,
      currentSeats: '1',
    };
    this.blurTimeoutId = null;
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.resetDates = this.resetDates.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.focusedInput && this.props.focusedInput !== prevProps.focusedInput) {
      this.setState({ focusedInput: this.props.focusedInput });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.blurTimeoutId);
    }
  }

  onDatesChange(dates) {
    const { isDaily, isBlockedBetween } = this.props;
    const { startDate, endDate } = dates;

    const startDateUpdated =
      isBlockedBetween &&
      startDate &&
      endDate &&
      this.state.currentStartDate &&
      startDate.isBefore(this.state.currentStartDate);

    let startDateAsDate = startDate instanceof moment ? startDate.startOf('day').toDate() : null;
    const isExperienceType = this.props.isExperienceType;
    const increasedDays =  this.props?.autoIncreaseDays ? Number(this.props?.autoIncreaseDays) - 1 : 0 ;
    let endDateAsDate;
    if(isExperienceType && (increasedDays !== 0)){
     endDateAsDate = startDate ? startDate.clone().add(increasedDays, 'days').toDate() : null;
      if (startDateUpdated) {
        const clearEndDate = isBlockedBetween(
          startDate,
          moment(this.state.currentStartDate).add(1, 'days')
        );
        endDateAsDate = clearEndDate ? null : pickerEndDateToApiDate(isDaily, endDateAsDate);
      }
    }else{
       endDateAsDate = pickerEndDateToApiDate(isDaily, endDate);

      if (startDateUpdated) {
        const clearEndDate = isBlockedBetween(
          startDate,
          moment(this.state.currentStartDate).add(1, 'days')
        );
        endDateAsDate = clearEndDate ? null : pickerEndDateToApiDate(isDaily, endDate);
      }
    }

    this.setState({ currentStartDate: startDateAsDate });
    this.props.onChange({ startDate: startDateAsDate, endDate: endDateAsDate });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
    if (typeof window !== 'undefined') {
      if (focusedInput) {
        window.clearTimeout(this.blurTimeoutId);
        this.props.onFocus(focusedInput);
      } else {
        window.clearTimeout(this.blurTimeoutId);
        this.blurTimeoutId = window.setTimeout(() => {
          this.props.onBlur();
        }, BLUR_TIMEOUT);
      }
    }
  }

  resetDates() {
    this.setState({ currentStartDate: null });
    this.props.onChange({ startDate: null, endDate: null });
  }

  render() {
    const {
      className,
      isDaily,
      initialDates,
      intl,
      name,
      startDatePlaceholderText,
      endDatePlaceholderText,
      onBlur,
      onChange,
      onFocus,
      screenReaderInputMessage,
      useMobileMargins,
      value,
      children,
      render,
      isBlockedBetween,
      isDayBlocked,
      isOutsideRange,
      isExperienceType,
      autoIncreaseDays,
      ...datePickerProps
    } = this.props;
    const initialStartMoment = initialDates ? moment(initialDates.startDate) : null;
    const initialEndMoment = initialDates ? moment(initialDates.endDate) : null;
    const startDate =
      value && value.startDate instanceof Date ? moment(value.startDate) : initialStartMoment;
    const endDate =
      apiEndDateToPickerDate(isDaily, value ? value.endDate : null) || initialEndMoment;

    const startDatePlaceholderTxt =
      startDatePlaceholderText ||
      intl.formatMessage({ id: 'FieldDateRangeInput.startDatePlaceholderText' });
    const endDatePlaceholderTxt =
      endDatePlaceholderText ||
      intl.formatMessage({ id: 'FieldDateRangeInput.endDatePlaceholderText' });
    const screenReaderInputText =
      screenReaderInputMessage ||
      intl.formatMessage({ id: 'FieldDateRangeInput.screenReaderInputMessage' });

    const classes = classNames(css.inputRoot, className, {
      [css.withMobileMargins]: useMobileMargins,
    });

    if (typeof window !== 'undefined' && window?.document) {
      window?.document?.addEventListener('DOMContentLoaded', function() {
        var startDateInput = document.getElementById('OrderPanelBookingDatesForm.bookingStartDate');
        var endDateInput = document.getElementById('OrderPanelBookingDatesForm.bookingEndDate');

        if (startDateInput) {
          startDateInput.classList.add('customStartInput');
        }

        if (endDateInput) {
          endDateInput.classList.add('customEndInput');
        }
      });
    }

    if (this.state.currentSeats != datePickerProps.seatsChanged) {
      this.setState({ currentSeats: datePickerProps.seatsChanged });
      this.resetDates();
    }
    return (
      <div className={classes}>
        <DateRangePicker
          {...datePickerProps}
          isDayBlocked={isDayBlocked(this.state.focusedInput)}
          isOutsideRange={isOutsideRange(this.state.focusedInput)}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.onFocusChange}
          startDate={startDate}
          endDate={endDate}
          minimumNights={isDaily ? 0 : 1}
          onDatesChange={this.onDatesChange}
          startDatePlaceholderText={startDatePlaceholderTxt}
          endDatePlaceholderText={endDatePlaceholderTxt}
          screenReaderInputMessage={screenReaderInputText}
        />
      </div>
    );
  }
}

DateRangeInputComponent.defaultProps = {
  className: null,
  useMobileMargins: false,
  ...defaultProps,
};

DateRangeInputComponent.propTypes = {
  className: string,
  startDateId: string,
  endDateId: string,
  isDaily: bool.isRequired,
  focusedInput: oneOf([START_DATE, END_DATE]),
  initialDates: instanceOf(Date),
  name: string.isRequired,
  isBlockedBetween: func,
  isDayBlocked: func,
  isOutsideRange: func,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  useMobileMargins: bool,
  useMobileMargins: bool,
  startDatePlaceholderText: string,
  endDatePlaceholderText: string,
  screenReaderInputMessage: string,
  value: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
  }),

  // from injectIntl
  intl: intlShape.isRequired,
  isExperienceType: bool,
  autoIncreaseDays: number,
};

export default injectIntl(DateRangeInputComponent);
